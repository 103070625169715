@use "../../../../../styles/variables.module" as *;
@use "../../../../../styles/mixins";

.newsBlockContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.editableTitle {
  font-weight: 500;
  font-size: 32px;
  padding: 0px;
  display: inline;

  h2, span {
    font-size: $terrace-title-font-size;
    @media(max-width: $break-point-sm) {
      font-size: $terrace-title-font-size-mobile;
    }
  }

  &Parent {
    display: block;
  }

  span[contenteditable="false"] {
    // fixes what looks like a bug where if text is empty the placeholder gets pushed off the page
    width: auto !important;
  }

  * {
    display: inline;
    position: unset;
  }

  span {
    padding-right: 10px;
  }
}

.titleAndEditPencil {
  margin-bottom: 10px;
}

.noContent {
  width: 100%;
  height: 150px;
  text-align: center;
  padding-top: 50px;
  font-weight: bold;
  font-size: 18px;
}

.blockTitle {
  display: inline-block;
  text-align: left;
  font-size: $terrace-title-font-size;
}

:global(.sb-with-background-image) .titleContainer {
  @media (min-width: $break-point-sm) {
    max-width: $terrace-title-max-width;
  }
}

.titleContainer {
  position: relative;
  padding-bottom: 5px;
}

.toggleClassName {
  box-shadow: 2px 2px 2px #ccc;
  line-height: 40px;
  white-space: nowrap;
  display: inline !important;
  padding: 10px 0;

  span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    max-width: 50%;
  }
  @media (max-width: $break-point-sm) {
    margin-bottom: 5px;
  }
  label {
    overflow: hidden;
    @media (max-width: $break-point-md) {
      font-size: 14px;
    }
    span {
      display: inline-block;
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  @include mixins.toggleColorMixin(var(--secondaryNavBackground), var(--secondaryNavBackgroundTextColor));
}

.editButton {
  > div {
    > div {
      top: 50px;
    }
  }
}

.blockTitleContainerClassName {
  margin-bottom: 0;
}

.editDropDown {
  display: inline-block;
  height: 20px;
}

.transitionContainer{
  opacity:0;
  transition: opacity 0.25s ease, transform .25s ease;
}
.transitionWrapper {
  @include mixins.opacityTransition(0, 1);
  @include mixins.translateTransition(translateY(-10%), translateY(0));
}

.viewMoreButton {
  text-align: center;
}

.newsSelectorClassName {
  > div:first-of-type {
    > div {
      > div {
        > div {
          div {
            position: relative !important;
          }
        }
      }
    }
  }
  @media (max-width: $break-point-sm) {
    > div:nth-of-type(2) {
      ul {
        li:nth-child(n+5) {
          display: none;
        }
      }
    }
  }
}
